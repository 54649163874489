<template>
  <div v-loading="isLoading" class="faq-page page">
    <div class="faq-page__wrapper">
      <div class="faq-page__left">
        <div class="faq-page__title" :class="{ 'locale-usa': locale === 'USA' }">
          <Title :text="$t('faq.title')" />
        </div>
        <div v-if="activeQuestion" class="faq-page__content" v-html="activeQuestion.text" />
      </div>
      <div class="faq-page__sidebar-wrapper">
        <div class="faq-page__sidebar">
          <div v-for="(question, index) in (isOpenReferral ? referralQuestions : filteredQuestions)" :key="index" class="faq-page__sidebar-item"
            :class="{ 'is-active': activeQuestion && activeQuestion.id === question.id }"
            @click="setActiveQuestion(question)">
            {{ question.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/elements/Title";
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: "HelpDesc",
  components: {
    Title,
  },
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      lang: this.$i18n._vm.locale,
      activeQuestion: null,
      activeTab: 0,
      referralQuestions: [
        {
          id: 0, active: true, title: "Как работает реферальная программа?", text: "Вы уже зарегистрировались в личном кабинете и теперь можете привлекать клиентов на наш сервис, чтобы получать доход по нашей реферальной программе — определенный процент с оборота клиентов(расходы на рекламу).Клиенту нужно всего лишь зарегистрироваться по вашей реферальной ссылке, доступной в личном кабинете, и начать пополнять свой баланс."
        },
        { id: 1, active: true, title: "Какие документы необходимо подписать, чтобы начать работать?", text: "Никакие документы подписывать не нужно, при регистрации в партнерской программе вы соглашаетесь на условия договора-оферты, который можно найти по ссылке: <a href='https://profitads.ru/dogovor-oferty-ref/' target='_blank'>https://profitads.ru/dogovor-oferty-ref/</a>" },
        { id: 2, active: true, title: "Сколько клиентов надо привести, чтобы получать вознаграждение?", text: "Вы будете получать вознаграждение даже, если по вашей ссылке зарегистрировался один клиент. Но помните, что чем больше клиентов вы приведете, тем выше будет ваше вознаграждение." },
        { id: 3, active: true, title: "Вознаграждение я буду получать бонусами на рекламные расходы или деньгами?", text: "Вы можете выбрать как вам удобнее получать вознаграждение деньгами или бонусами на рекламные расходы" }
      ]
    };
  },
  computed: {
    ...mapState('faq', ['pagination', 'questions', 'isLoading']),
    filteredQuestions() {
      return this.questions.filter(question => question.active === true)
    },
    isOpenReferral() {
      const open = localStorage.getItem('isOpenReferral')
      return open === 'true' ? true : false
    }
  },
  methods: {
    ...mapActions('faq', ['fetchFaq']),
    ...mapMutations('faq', ['clearPagination']),
    fetchNewFaq(page = 1) {
      // return this.fetchFaq({ params: { per_page: 10, page: page } })
      return this.fetchFaq()
    },
    setActiveQuestion(question) {
      this.activeQuestion = question
    }
  },
  created() {
    this.fetchNewFaq().then((response) => {
      const questions = response.data
      if(this.isOpenReferral) {
        this.setActiveQuestion(this.referralQuestions[0])
      }else if (questions && questions.length) {
        this.setActiveQuestion(questions[0])
      }
    })
  },
  destroyed() {
    this.clearPagination()
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.faq-page {
  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .el-pagination .btn-next,
    .el-pagination .btn-prev,
    .el-pager li {
      background: transparent;
    }
  }

  &>.faq-page__title {
    display: none;

    @include below(980px) {
      display: block;
    }
  }

  .router-link-exact-active {
    color: var(--primary);
  }

  &__contents {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    >li {
      list-style: none;
      line-height: normal;

      a {
        display: block;
      }
    }
  }

  &__title {
    margin-bottom: 3.2rem;

    &.locale-usa {
      &.locale-usa {
        font-family: 'Gilroy';
        font-style: normal;

        .title {
          font-weight: 700;
          font-size: 3.2rem;
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    @include below(980px) {
      flex-direction: column-reverse;
    }
  }

  &__left {
    width: 100%;
    max-width: 70rem;
    padding-right: 2rem;

    &>.faq-page__title {
      @include below(980px) {
        display: none;
      }
    }
  }

  &__content {
    font-size: 1.6rem;

    h3 {
      margin-bottom: 2rem;
      font-size: 2rem;
    }

    ul,
    ol {
      padding-left: 2rem;

      li {
        margin: 0 0 1.5rem;
      }
    }

    a {
      font-weight: 700;
    }

    p {
      margin: 0 0 1.5rem;
    }
  }

  &__sidebar-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__sidebar {
    width: 37rem;
    padding: 2.4rem 3rem;
    background: #ffffff;
    box-shadow: 0px 5px 10px rgba(158, 158, 158, 0.1);
    border-radius: 6px;
    height: 100%;

    @include below(980px) {
      width: 100%;
      margin-bottom: 2rem;
    }

    &-item {
      font-weight: 500;
      font-size: 1.6rem;
      margin-bottom: 2rem;
      cursor: pointer;

      &.is-active {
        color: var(--primary);
        position: relative;

        &:before {
          content: "-";
          color: var(--primary);
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(-10px, -50%);
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
